<template>
  <div class="home" v-loading="loading">
    <div class="menu-header">
      <div class="opt-box">
        <div class="opt-item" v-if="showOriginTab">
          <span class="f-b">原始数据一共有：</span>
          <span>{{ originListBak.length }} 条</span>
        </div>
        <div class="opt-item" v-if="showTestTab || showRgTab">
          <span class="m-l-5 f-b">期数:</span>
          <el-input v-model="form.period" placeholder="期数" style="width: 55px" size="mini" />
        </div>
        <div class="opt-item" v-if="showRgTab">
          <span class="m-l-5 f-b">选择红绿期数:</span>
          <el-input
            v-model="form.rgPeriod"
            placeholder="期数"
            style="width: 55px"
            size="mini"
            class="m-l-5"
          />
          <el-button type="primary" @click="calcRgReport" class="m-l-5" size="mini">计算</el-button>
        </div>
        <div class="opt-item" v-if="showTestTab">
          <span class="f-b">预测期数:</span>
          <el-select v-model="form.testGroup" style="width: 90px" size="mini">
            <el-option
              v-for="(item,index) in originList"
              :key="index"
              :label="index + '_' +item.group"
              :value="index"
            />
          </el-select>
          <el-button type="primary" @click="calcAndPredict" class="m-l-5" size="mini">计算</el-button>
        </div>
        <div v-if="maxScoreRecord.totalScore > 0 && (showTestTab || showRgTab)" class="predict-box">
          <span class="m-l-5 f-b">{{maxScoreRecord.predictData.group+1}}期预测结果:</span>
          <span class="m-l-5">{{ maxScoreRecord.totalScore }}({{maxScoreRecord.orderNo}})</span>
          <span class="m-l-5 f-b">号码:</span>
          <span v-for="ri in secondStepHeaderList" :key="ri">
            {{
            maxScoreRecord[ri].location
            }}
          </span>
        </div>
      </div>
      <ul>
        <li @click="handleClickShowRgReport">统计红蓝图</li>
        <li @click="handleClickShowTest">测试</li>
        <li @click="handleClickShowOriginData">原始数据</li>
      </ul>
    </div>
    <div v-if="showOriginTab">
      <u-table
        ref="plTable"
        :data="originList"
        :height="700"
        use-virtual
        showBodyOverflow="title"
        showHeaderOverflow="title"
        :row-height="40"
        border
      >
        <u-table-column prop="group" label="期数" fixed sortable />
        <u-table-column prop="hao1" label="1号" fixed />
        <u-table-column prop="hao2" label="2号" fixed />
        <u-table-column prop="hao3" label="3号" fixed />
        <u-table-column prop="hao4" label="4号" fixed />
        <u-table-column prop="hao5" label="5号" fixed />
        <u-table-column prop="hao6" label="6号" fixed />
        <u-table-column prop="hao7" label="7号" fixed />
      </u-table>
    </div>

    <div v-if="showTestTab">
      <u-table
        ref="hotTable"
        :data="hotResult"
        :height="700"
        use-virtual
        showBodyOverflow="title"
        showHeaderOverflow="title"
        :row-height="55"
        border
      >
        <u-table-column prop="orderNo" width="50" label="组" sortable />
        <u-table-column prop="totalScore" label="分数" sortable v-if="showTotalScore" width="50">
          <template #default="scope">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="scope.row.totalScoreRecord"
              placement="top"
            >
              <span>{{ scope.row.totalScore }}</span>
            </el-tooltip>
          </template>
        </u-table-column>
        <u-table-column
          v-for="i in secondStepHeaderList"
          :key="i"
          :label="i + ''"
          :prop="String(i) + '.location'"
          title
          width="30"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="
                  String(scope.row[i].score) + ' || ' + scope.row[i].desc
                "
              placement="top"
            >
              <span :class="{ 'zero-span': scope.row[i].score == 0 }">
                {{
                scope.row[i].location
                }}
              </span>
            </el-tooltip>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <div v-if="showRgTab">
      <u-table
        ref="hotTable"
        :data="rgResult"
        :height="700"
        use-virtual
        showBodyOverflow="title"
        showHeaderOverflow="title"
        :row-height="40"
        border
      >
        <u-table-column prop="realDataObj.group" label="期数" width="40" />
        <u-table-column prop="realDataObj.hao1" label="1" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao2" label="2" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao3" label="3" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao4" label="4" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao5" label="5" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao6" label="6" :width="haoWidth" />
        <u-table-column prop="realDataObj.hao7" label="7" :width="haoWidth" />
        <u-table-column prop="realDataObj.group" label="预测期" width="50"></u-table-column>
        <u-table-column prop="totalScore" label="分数" width="30" />
        <u-table-column prop="bestGroup" label="最优期" width="50" />
        <u-table-column
          v-for="(item, index) in rgTableHeader"
          :key="index"
          :label="item.name + '(红:' + item.red + '||'+'蓝:' + item.blue +')'"
          :class-name="item.class"
        >
          <u-table-column
            v-for="(i, subIndex) in item.list"
            :key="item.name + '_' + index + '_' + subIndex"
            :label="i + ''"
            :prop="String(i) + '.location'"
            :class-name="item.class"
            label-class-name="hao-title"
            :width="haoWidth"
          >
            <template slot-scope="scope">
              <span
                :class="{
                    'zero-span': scope.row[i].score == 0,
                    'red-alert': scope.row[i].type == 'red',
                    'blue-alert': scope.row[i].type == 'blue',
                  }"
              >{{ scope.row[i].location }}</span>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      SOURCE_SHEET_NAME: "Sheet1",
      originListBak: [],
      haoWidth: 25,
      originList: [],
      form: {
        period: 100,
        rgPeriod: 100,
        testGroup: 0,
      },
      showOriginData: true,
      hotResult: [],
      showTotalScore: false,
      showOriginTab: false,
      showTestTab: false,
      showRgTab: true,
      predictData: {},
      secondStepHeaderList: [],
      loading: false,
      maxScoreRecord: { totalScore: 0 },
      resultIndex: ["49", "48", "47", "46", "45", "44", "43"],
      rgResult: [],
      rgTableHeader: [
        {
          class: "c-red",
          name: "第一区间(49-40)",
          list: ["49", "48", "47", "46", "45", "44", "43", "42", "41", "40"],
          red: 0,
          blue: 0,
        },
        {
          class: "c-green",
          name: "第二区间(39-30)",
          list: ["39", "38", "37", "36", "35", "34", "33", "32", "31", "30"],
          red: 0,
          blue: 0,
        },
        {
          class: "c-red",
          name: "第三区间(29-20)",
          list: ["29", "28", "27", "26", "25", "24", "23", "22", "21", "20"],
          red: 0,
          blue: 0,
        },
        {
          class: "c-green",
          name: "第四区间(19-11)",
          list: ["19", "18", "17", "16", "15", "14", "13", "12", "11"],
          red: 0,
          blue: 0,
        },
        {
          class: "c-red",
          name: "第五区间(10-1)",
          list: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
          red: 0,
          blue: 0,
        },
      ],
    };
  },
  async created() {
    await this.readDefaultFile();
    for (let i = 49; i > 0; i--) {
      this.secondStepHeaderList.push(String(i));
    }
  },
  methods: {
    async readDefaultFile() {
      var url = "/static/template/source.xlsx";
      const data = await (await fetch(url)).arrayBuffer();
      const workbook = XLSX.read(data);
      this.readWorkbook(workbook);
    },
    readWorkbook(workbook) {
      var sheetNames = workbook.SheetNames;
      var sourceName = this.SOURCE_SHEET_NAME;
      if (sheetNames.indexOf(sourceName) == -1) {
        sourceName = sheetNames[0];
      }
      var worksheet = workbook.Sheets[sourceName];
      var csv = XLSX.utils.sheet_to_json(worksheet);
      this.originList = [].concat(csv);
      this.originList.reverse();
      this.originListBak = csv;
      this.predictData = this.originListBak.pop();
    },
    calcHotReport(sourceData, hotPeriod) {
      let finalResult = [];
      // cut new list by period
      let newList = sourceData.slice(sourceData.length - hotPeriod);
      //开始构造所有的热力组合
      for (let i = 1; i < newList.length + 1; i++) {
        //需要统计的期数
        let calcList = newList.slice(newList.length - i);
        let groupScore = {};
        for (let x = 1; x < 50; x++) {
          groupScore[String(x)] = { score: 0, group_size: i, desc: "" };
        }
        for (let x = 0; x < calcList.length; x++) {
          // { "group": 111, "hao1": 39, "hao2": 48, "hao3": 37, "hao4": 41, "hao5": 8, "hao6": 21, "hao7": 10}
          let objArray = calcList[x];
          for (let key in objArray) {
            if (key == "group") {
              continue;
            }
            let keyNumber = key.replace("hao", "").trim();
            let currentValue = objArray[key];
            if (
              currentValue == undefined ||
              currentValue < 1 ||
              currentValue > 49
            ) {
              alert("当前数据为空,或者不在1-49范围内");
              return;
            }
            try {
              keyNumber = parseInt(keyNumber);
            } catch (error) {
              alert("表头数据不是数字类型");
              return;
            }
            groupScore[currentValue].score =
              groupScore[currentValue].score + 50 - keyNumber;
            if (groupScore[currentValue].desc != "") {
              groupScore[currentValue].desc =
                groupScore[currentValue].desc + " + ";
            }
            groupScore[currentValue].desc =
              groupScore[currentValue].desc +
              `(${objArray["group"]}-${keyNumber})${50 - keyNumber}`;
          }
        }
        // 把获取的对象转成list，为了进行排序
        let groupDataList = [];
        for (let key in groupScore) {
          groupDataList.push({
            location: key,
            score: groupScore[key].score,
            desc: groupScore[key].desc,
            group_size: groupScore[key].group_size,
          });
        }

        groupDataList = groupDataList.sort((a, b) => {
          if (a.score != b.score) {
            return a.score - b.score;
          } else {
            return a.location - b.location;
          }
        });
        // 再把list转化对对象，方便进行展示
        let newGroupScore = {};
        for (let a = 0; a < groupDataList.length; a++) {
          newGroupScore[String(a + 1)] = groupDataList[a];
        }
        newGroupScore["orderNo"] = i;
        finalResult.push(newGroupScore);
      }
      return finalResult;
    },
    calcMaxScore(sourceData, hotPeriod, predictObj) {
      if (hotPeriod > sourceData.length) {
        alert("计算期数不能超过总数据期数");
        return;
      }
      // 1st step get hot data
      let hotResult = this.calcHotReport(sourceData, hotPeriod);
      // 2nd step get max score
      let maxScoreRecord = this.calcMaxScoreRecord(hotResult, predictObj);
      return maxScoreRecord;
    },
    calcMaxScoreRecord(hotResult, periodObj) {
      let maxRecord = { totalScore: 0 };
      for (let i = 0; i < hotResult.length; i++) {
        let item = hotResult[i];
        let calcTotalScoreRecord = "计算规则 = ";
        let newObj = {};
        for (let key in item) {
          newObj[item[key].location] = key;
        }
        let totalScore = 0;
        for (let key in periodObj) {
          if (key == "totalscore" || key == "group") {
            continue;
          }
          let curLocation = String(periodObj[key]);
          let mapNumber = parseInt(newObj[curLocation]);
          if (totalScore > 0) {
            calcTotalScoreRecord = calcTotalScoreRecord + " + ";
          }
          totalScore = totalScore + mapNumber;
          calcTotalScoreRecord =
            calcTotalScoreRecord + curLocation + "(" + mapNumber + ")";
        }
        item["totalScore"] = totalScore;
        item["totalScoreRecord"] = calcTotalScoreRecord;
        // 谁分数大选谁
        if (totalScore > maxRecord.totalScore) {
          Object.assign(maxRecord, item);
        }
        // 分数一样的选组合少的
        if (
          totalScore == maxRecord.totalScore &&
          item.orderNo < maxRecord.orderNo
        ) {
          Object.assign(maxRecord, item);
        }
      }
      return maxRecord;
    },
    async calcAndPredict() {
      this.loading = true;
      let sourceData = [].concat(this.originList).reverse();
      // console.log(this)
      let newTable = sourceData.slice(
        0,
        sourceData.length - this.form.testGroup
      );
      let predictObj = newTable.pop();
      this.hotResult = this.calcHotReport(newTable, this.form.period);

      this.maxScoreRecord = this.calcMaxScoreRecord(this.hotResult, predictObj);
      this.maxScoreRecord["predictData"] = predictObj;
      this.showTotalScore = true;
      this.$refs.hotTable.reloadData(this.hotResult);

      this.loading = false;
    },
    setRedBlueInHeader(location, type) {
      location = parseInt(location);
      if (location >= 40) {
        this.rgTableHeader[0][type] = this.rgTableHeader[0][type] + 1;
        return;
      }
      if (40 > location && location >= 30) {
        this.rgTableHeader[1][type] = this.rgTableHeader[1][type] + 1;
        return;
      }
      if (30 > location && location >= 20) {
        this.rgTableHeader[2][type] = this.rgTableHeader[2][type] + 1;
        return;
      }
      if (20 > location && location >= 11) {
        this.rgTableHeader[3][type] = this.rgTableHeader[3][type] + 1;
        return;
      }
      if (11 > location && location >= 1) {
        this.rgTableHeader[4][type] = this.rgTableHeader[4][type] + 1;
        return;
      }
    },
    calcRgReport() {
      this.rgTableHeader.forEach((item) => {
        item.blue = 0;
        item.red = 0;
      });
      this.loading = true;
      let result = [];
      let sourceData = [].concat(this.originList).reverse();
      let realDataObj = Object.assign({}, sourceData.pop());
      // 拿取最后一条记录进行下一期的预测
      let lastRecordForPredict = Object.assign({}, realDataObj);
      let nextMaxScoreRecord = this.calcMaxScore(
        sourceData,
        this.form.period,
        lastRecordForPredict
      );
      nextMaxScoreRecord["predictData"] = lastRecordForPredict;
      this.maxScoreRecord = nextMaxScoreRecord;
      for (let i = 0; i < this.form.rgPeriod; i++) {
        let predictObj = sourceData.pop();
        let maxScoreRecord = this.calcMaxScore(
          sourceData,
          this.form.period,
          predictObj
        );
        for (let key in maxScoreRecord) {
          if (key.indexOf("total") > -1 || key == "orderNo") {
            continue;
          }
          if (realDataObj["hao1"] == maxScoreRecord[key].location) {
            maxScoreRecord[key]["type"] = "blue";
            this.setRedBlueInHeader(key, "blue");
            continue;
          }
          if (realDataObj["hao7"] == maxScoreRecord[key].location) {
            maxScoreRecord[key]["type"] = "red";
            this.setRedBlueInHeader(key, "red");
            continue;
          }
          maxScoreRecord[key]["type"] = "none";
        }
        maxScoreRecord["predictData"] = predictObj;
        maxScoreRecord["realDataObj"] = Object.assign({}, realDataObj);
        maxScoreRecord["bestGroup"] = maxScoreRecord["1"].group_size;
        result.push(maxScoreRecord);
        this.realDataObj = {};
        Object.assign(realDataObj, predictObj);
      }
      this.rgResult = result;
      this.loading = false;
    },
    handleClickShowOriginData() {
      this.showOriginTab = true;
      this.showTestTab = false;
      this.showRgTab = false;
    },
    handleClickShowTest() {
      this.showOriginTab = false;
      this.showTestTab = true;
      this.showRgTab = false;
    },
    handleClickShowRgReport() {
      this.showOriginTab = false;
      this.showTestTab = false;
      this.showRgTab = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.home
  padding: 10px
  .opt-box
    text-align: left
    padding: 2px
  .opt-item
    padding: 2px
    display: inline-block
  .origin-table
    margin-top: 5px
  .m-l-5
    margin-left: 5px
  .pre-cell
    width: 50px
    margin-left: 2px
  .f-b
    font-weight: bolder
  .predict-box
    display: inline-block
    background: #c5b9b9
    padding: 3px
    margin: 3px
    border-radius: 3px
  .menu-header
    display: flex
    justify-content: space-between
    align-items: center
    ul
      text-align: right
    li
      list-style: none
      display: inline-block
      padding: 5px 10px
      background: #6565fd
      color: white
      font-size: 19px
      font-weight: 900
      margin-left: 1px
      border-radius: 6px
      cursor: pointer
    li:hover
      background: blue
.zero-span
  color: #e59b9b
.red-alert
  background: red
  color: white
  font-weight: bold
  padding: 1px
.blue-alert
  background: #8f71e2
  color: white
  font-weight: bold
  padding: 1px
</style>
<style>
.home .hao-title {
  color: black;
}
.c-red {
  background: #cce4ee !important;
  text-align: center !important;
  color: #303133;
}
.c-green {
  background: #bbe3bbc7 !important;
  text-align: center !important;
  color: #303133;
}
.home .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center;
}
</style>
